import axios from "axios";
import { config } from "src/config";


function createUrl(accountId, groupId, entityName) {
    let url = `/${entityName}`;

    if (groupId) {
        url = `/groups/${groupId}${url}`;
    }

    if (accountId) {
        url = `/accounts/${accountId}${url}`;
    }

    return url;
}

export const fetchEntitiesApi = async (accountId, groupId, entityName) => {

    const url = createUrl(accountId, groupId, entityName);

    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const createEntityApi = async (accountId, groupId, entityName, entity) => {
    try {

        const url = createUrl(accountId, groupId, entityName);

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `${url}`,
            method: 'post',
            data: entity
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const updateEntityApi = async (accountId, groupId, entityName, entity) => {
    try {

        const url = createUrl(accountId, groupId, entityName);


        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'put',
            data: entity
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const deleteEntityApi = async (accountId, groupId, entityName, entitiy) => {
    try {

        const url = createUrl(accountId, groupId, entityName);

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'DELETE',
            data: entitiy
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}
