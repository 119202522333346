import axios from 'axios';
import { config } from 'src/config';
import store from 'src/store';
import { logout, userAuthRequestFailed } from 'src/store/auth';
import { addToast } from "../store/ui";
import { logLevels, logSeverity } from './constants';
import { logOnWatchtower } from './watchtower';

let responseInterceptorId;

export const configureNetworkSettingsForAuthenticatedUser = () => {
    setHeader();
    setResponseInterceptor();
}

// AUTH HEADER WITH EVERY REQUEST
const setHeader = () => {
    const authState = store.getState().auth;
    axios.defaults.headers.common['Authorization'] = `Bearer ${authState.token}`;
}

// response interceptor
const setResponseInterceptor = () => {

    // we have to use type of because the id can be 0
    if (typeof responseInterceptorId != "undefined") {
        // interceptor already created, this method can be called twice
        // incase there is any error in DOM
        // probably not gonna happen in prod
        return;
    }

    responseInterceptorId = axios.interceptors.response.use(
        res => res,
        err => {

            if (isWatchTowerUrl(err)) {
                return Promise.reject(err);
            }

            logNetworkError(err);

            if (err.response.status === 401) {
                store.dispatch(userAuthRequestFailed("Je bent uitgelogd, omdat je sessie is verlopen. Om veiligheidsredenen dien je elk uur opnieuw in te loggen"));
                store.dispatch(logout());

            } else {
                store.dispatch(addToast({
                    color: 'danger',
                    headerMessage: 'Foutmelding',
                    message: err.response.statusText || err.response.data.message || err.response.data.message || "Er is iets fout gegaan."
                }))

            }

            return Promise.reject(err);

        });
}

// this will be called from logout side effect
export const cancelAxiosResponseInterceptor = () => {
    axios.interceptors.response.eject(responseInterceptorId);
    responseInterceptorId = null;

}

const logNetworkError = ({ response }) => {
    const message = `Call to ${response.config.baseURL || ""}${response.config.url || ""} failed with status code ${response.status}`;
    const logLevel = response.status === 401 ? logLevels.INFO : logLevels.ERROR;
    logOnWatchtower(logLevel,
        message,
        {
            ...response.data,
            type: 'Request error',
            accountId: store.getState().accounts.selectedAccount,
            groupId: store.getState().groups.selectedGroup,
            userId: store.getState().auth.userId
        },
        logSeverity.ERROR,
        true);
}

const isWatchTowerUrl = ({ response }) => {
    const url = (response.config.baseURL || "") + (response.config.url || "");
    return url === config.getWatchtowerUrl();
}
