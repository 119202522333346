
import { createAction, createSlice } from '@reduxjs/toolkit';



const usersSlice = createSlice({
    initialState: { allUsers: [], isLoaidng: false },
    name: 'users',
    reducers: {
        fetchUsers: (state, action) => {
            state.isLoading = true;
        },
        usersFetched: (state, action) => {
            state.allUsers = action.payload;
            state.isLoading = false;
        },
        userCreated: (state, action) => {
            state.allUsers.push(action.payload);
        },
        userUpdated: (state, action) => {
            const index = state.allUsers.findIndex(user => user.id === action.payload.id);
            state.allUsers[index] = action.payload;
        },
        deleteUser: (state, action) => {
           state.isLoaidng = true;
        },
        userDeleted: (state, action) => {
            state.allUsers  = state.allUsers.filter(user => user.id !== action.payload.userId);
            state.isLoaidng = false;

         }
    }
});

// Action Creators
export const createUser = createAction('users/createUser');
export const updateUser = createAction('users/updateUser');

export const { usersFetched, fetchUsers, userCreated, userUpdated, deleteUser, userDeleted } = usersSlice.actions;

export default usersSlice.reducer;


