import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';
import { createEntityApi, deleteEntityApi, fetchEntitiesApi, updateEntityApi } from 'src/api/entities';
import { appEntities } from 'src/utils/constants';
import { formSubmissionStarted, formSubmitFailed, formSubmitted } from '../ui';

import { createUser, deleteUser, fetchUsers, updateUser, userCreated, userDeleted, usersFetched, userUpdated } from '../users';

function* getUsersFromServer(action) {

    try {
        const usersResponse = yield call(fetchEntitiesApi, null, null, appEntities.USERS);
        yield put(usersFetched(usersResponse.result));
    } catch (e) {
    }

}


function* createUserOnServer(action) {

    try {
        yield put(formSubmissionStarted());
        const response = yield call(createEntityApi, null, null, appEntities.USERS, action.payload);
        yield put(userCreated({ ...action.payload, id: response.result.id }));
        yield put(formSubmitted());

    } catch (e) {
        yield put(formSubmitFailed());
        //TO DO
    }

}

function* updateUserOnServer(action) {

    try {
        yield put(formSubmissionStarted());
        yield call(updateEntityApi, null, null, appEntities.USERS, action.payload);
        yield put(userUpdated(action.payload));
        yield put(formSubmitted());
    } catch (e) {
        yield put(formSubmitFailed());
        //TO DO
    }

}

function* deleteUserOnServer(action) {

    try {
        yield put(formSubmissionStarted());
        yield call(deleteEntityApi, null, null, appEntities.USERS, action.payload);
        yield put(userDeleted(action.payload));
        yield put(formSubmitted());
    } catch (e) {
        yield put(formSubmitFailed());
        //TO DO
    }

}

export const usersSagas = [
    takeEvery(fetchUsers.type, getUsersFromServer),
    // takeEvery(deleteRole.type, deleteRoleFromServer),
    takeLatest(createUser.type, createUserOnServer),
    takeLatest(updateUser.type, updateUserOnServer),
    takeLatest(deleteUser.type, deleteUserOnServer),

]

