import { takeLatest, call, put } from 'redux-saga/effects';
import store from '..';
import {
    getHomeDataApi,
    getChecksDataApi,
    getConsentDetailsApi,
    getConsentUnitsApi,
    getDashboardDataApi,
    getRequestsData
} from '../../api/dashboard';
import {
    fetchChecksData,
    fetchHomeData,
    homeDataFetched,
    checksDataFetched,
    fetchConsentUnitDetails,
    fetchConsetUnits,
    consetUnitsFetched,
    consentUnitDetailsFetched,
    fetchDashboardData,
    dashboardDataFetched, fetchRequestsData
} from '../dashboard';

function* getHomeDataFromServer(action) {

    const selectedAccount = store.getState().accounts.selectedAccount;
    const group = store.getState().groups.selectedGroup;
    const { startDate, endDate } = store.getState().dashboard.dateRange;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;
    const currentWhitelistVersion = store.getState().whitelist.currentVersion;
    // const source = axios.CancelToken.source();
    try {
        const { data } = yield call(getHomeDataApi, selectedAccount, group, startDate, endDate, selectedWhitelistVersion || currentWhitelistVersion);
        yield put(homeDataFetched(data.results));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}

function* getChecksDataFromServer(action) {

    const selectedAccount = store.getState().accounts.selectedAccount;
    const group = store.getState().groups.selectedGroup;
    const { startDate, endDate } = store.getState().dashboard.dateRange;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;
    const currentWhitelistVersion = store.getState().whitelist.currentVersion;
    const dashboardSubtype = action.payload.dashboardType;
    try {
        const response = yield call(getChecksDataApi, selectedAccount, group, startDate, endDate, selectedWhitelistVersion || currentWhitelistVersion, dashboardSubtype);
        yield put(checksDataFetched({ dashboardSubtype, data: response.results }));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}
function* getRequestsDataFromServer(action) {

    const selectedAccount = store.getState().accounts.selectedAccount;
    const group = store.getState().groups.selectedGroup;
    const { startDate, endDate } = store.getState().dashboard.dateRange;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;
    const currentWhitelistVersion = store.getState().whitelist.currentVersion;
    const dashboardSubtype = action.payload.dashboardType;

    try {
        const response = yield call(getRequestsData, selectedAccount, group, startDate, endDate, dashboardSubtype, selectedWhitelistVersion || currentWhitelistVersion);
        yield put(checksDataFetched({ dashboardSubtype, data: response.results }));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}


function* getConsentUnitDetailsFromServer(action) {

    const selectedAccount = store.getState().accounts.selectedAccount;
    const group = store.getState().groups.selectedGroup;
    const { startDate, endDate } = store.getState().dashboard.dateRange;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;
    const currentWhitelistVersion = store.getState().whitelist.currentVersion;
    const consentUnitName = action.payload.id;
    const dashboardType = action.payload.dashboardType;
    try {
        const response = yield call(getConsentDetailsApi, selectedAccount, group, startDate, endDate, consentUnitName, selectedWhitelistVersion || currentWhitelistVersion,dashboardType);
        yield put(consentUnitDetailsFetched(response.results));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}

function* getConsentUnitsFromServer(action) {

    const selectedAccount = store.getState().accounts.selectedAccount;
    const group = store.getState().groups.selectedGroup;
    const { startDate, endDate } = store.getState().dashboard.dateRange;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;
    const currentWhitelistVersion = store.getState().whitelist.currentVersion;
    const { consentUnitType, purposeId, dashboardType, tableVersion } = action.payload;

    try {
        const response = yield call(getConsentUnitsApi, selectedAccount, group, startDate, endDate, consentUnitType, purposeId, selectedWhitelistVersion || currentWhitelistVersion, dashboardType, tableVersion);
        yield put(consetUnitsFetched({ consentUnitType, data: response.results.trackers }));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}

function* getDashboardDataFromServer(action) {

    const selectedAccount = store.getState().accounts.selectedAccount;
    const group = store.getState().groups.selectedGroup;
    const { startDate, endDate } = store.getState().dashboard.dateRange;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;
    const currentWhitelistVersion = store.getState().whitelist.currentVersion;
    const { dashboardType, dataType, responseKey = "trackers" } = action.payload;


    try {
        const response = yield call(getDashboardDataApi, selectedAccount, group, startDate, endDate, dataType, selectedWhitelistVersion || currentWhitelistVersion, dashboardType);
        yield put(dashboardDataFetched({ dataType, data: response.results[responseKey], dashboardType }));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}

export const dashboardSagas = [
    takeLatest(fetchHomeData.type, getHomeDataFromServer),
    takeLatest(fetchChecksData.type, getChecksDataFromServer),
    takeLatest(fetchRequestsData.type, getRequestsDataFromServer),
    takeLatest(fetchConsentUnitDetails.type, getConsentUnitDetailsFromServer),
    takeLatest(fetchConsetUnits.type, getConsentUnitsFromServer),
    takeLatest(fetchDashboardData.type, getDashboardDataFromServer),


]

