import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { createEntityApi } from "src/api/entities";
import { appEntities } from "src/utils/constants";
import store from "..";
import { createAccountApi, fetchAccountsApi, updateAccountApi } from "../../api/accounts";
import { fetchAccounts, accountsFetched, accountSelected, createAccount, updateAccount, accountCreated, accountUpdated } from "../accounts";
import { formSubmissionStarted, formSubmitFailed, formSubmitted } from "../ui";

function* getAccountsFromServer(action) {
	try {
		const accounts = yield call(fetchAccountsApi);
		yield put(accountsFetched(accounts.result));
	} catch (e) {}
}

function* persistAccountSelectionInLocalStorage(action) {
	const allAccounts = store.getState().accounts.allAccounts;
	const selectedAccountId = action.payload;
	const selectedAccount = allAccounts.find(account => account.id.toString() === selectedAccountId);
	yield localStorage.setItem("selectedAccount", JSON.stringify({ ...selectedAccount, id: selectedAccount.id.toString() }));
}

// POWER USER SPECIFIC FUNCTIONS
function* createAccountOnServer(action) {
	try {
		yield put(formSubmissionStarted());
		const response = yield call(createAccountApi, action.payload);
		yield call(createEntityApi, response.result.id, null, appEntities.INSTALL, {});
		yield put(accountCreated({ ...action.payload, id: response.result.id }));
		yield put(formSubmitted());
	} catch (e) {
		yield put(formSubmitFailed());
		//TO DO
	}
}

function* updateAccountOnServer(action) {
	try {
		// dispatch form submission event
		yield put(formSubmissionStarted());
		// call update API
		yield call(updateAccountApi, action.payload);
		yield put(formSubmitted());
		yield put(accountUpdated(action.payload));
	} catch (e) {
		console.log(e);
		//TO DO
		yield put(formSubmitFailed());
	}
}

export const accountsSagas = [
	takeEvery(fetchAccounts.type, getAccountsFromServer),
	takeEvery(accountSelected.type, persistAccountSelectionInLocalStorage),
	takeLatest(createAccount.type, createAccountOnServer),
	takeLatest(updateAccount.type, updateAccountOnServer),
];
