import { createSlice } from "@reduxjs/toolkit";
import { formModes, formStates } from "../utils/constants";

const uiSlice = createSlice({
	initialState: {
		sidebarShow: "responsive",
		form: {
			// default state
			state: formStates.INITIAL,
			showModal: false,
			mode: formModes.CREATE,
			entity: null,
			// we can also add named instance of the forms too, for example
			// "uuid": {mode, entity, showModal}
		},
		toasts: [],
		hightlightChanges: false,
	},
	name: "UI",
	reducers: {
		toggleSidebarAction: (state, action) => {
			state.sidebarShow = action.payload.sidebarShow;
		},
		formSubmissionStarted: (state, action) => {
			state.form.state = formStates.SUBMITTING;
		},
		formSubmitted: (state, action) => {
			state.form.state = formStates.SUBMITTED;
		},
		formSubmitFailed: (state, action) => {
			state.form.state = formStates.ERROR;
		},
		resetFormState: (state, action) => {
			state.form.state = formStates.INITIAL;
		},
		toggleModal: (state, action) => {
			const id = action.payload;
			// named isntance
			if (id) {
				state.form[id].showModal = !state.form[id].showModal;
				return;
			}
			// default form state
			state.form.showModal = !state.form.showModal;
		},
		setFormState: (state, action) => {
			const { formMode, entity, id, initialValues } = action.payload;

			// if id is passed, then set the state of the named form instance

			if (id) {
				state.form[id] = {
					mode: formMode,
					initialValues: initialValues || {},
					entity: entity,
				};

				return;
			}

			// set state of the default form state
			state.form.mode = formMode;
			state.form.entity = entity;
		},
		addToast: (state, action) => {
			state.toasts.push(action.payload);
		},
		toggleHighlightChanges: (state, action) => {
			state.hightlightChanges = !state.hightlightChanges;
		},
	},
});

export const {
	toggleSidebarAction,
	formSubmissionStarted,
	formSubmitted,
	formSubmitFailed,
	resetFormState,
	toggleModal,
	setFormState,
	addToast,
	toggleHighlightChanges,
} = uiSlice.actions;

export default uiSlice.reducer;
