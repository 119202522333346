import axios from "axios";
import { config } from "src/config";

export const publishHostsApi = async (groupId, hosts) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/1/groups/groupId/hosts/`,
            method: 'post',
            data: hosts
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}