import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { createEntityApi, fetchEntitiesApi } from "src/api/entities";
import { appEntities } from "src/utils/constants";
import store from "..";
import { createGroupApi, fetchGroupsApi, updateGroupApi } from "../../api/groups";
import { createGroup, fetchAllGroups, fetchGroups, groupCreated, groupSelected, groupsFetched, groupUpdated, updateGroup } from "../groups";
import { formSubmissionStarted, formSubmitFailed, formSubmitted } from "../ui";

function* getGroupsFromServer(action) {
	try {
		const accountId = action.payload;
		const groupsResponse = yield call(fetchGroupsApi, accountId);
		yield put(groupsFetched(groupsResponse.result));
	} catch (e) {}
}

function* getAllGroupsFromServer(action) {
	try {
		const groupsResponse = yield call(fetchEntitiesApi, null, null, appEntities.GROUPS);
		yield put(groupsFetched(groupsResponse.result));
	} catch (e) {}
}

function* persistGroupSelectionInLocalStorage(action) {
	const allGroups = store.getState().groups.allGroups;
	const selectedGroupId = action.payload;
	const selectedGroup = allGroups.find(group => group.id.toString() === selectedGroupId);
	if (selectedGroup) {
		yield localStorage.setItem("selectedGroup", JSON.stringify(selectedGroup));
	}
}

// POWER USER SPECIFIC FUNCTIONS
function* createGroupOnServer(action) {
	try {
		const selectedAccount = store.getState().accounts.selectedAccount;

		yield put(formSubmissionStarted());
		const response = yield call(createGroupApi, selectedAccount, action.payload);
		yield call(createEntityApi, selectedAccount, response.result.id, appEntities.INSTALL, {});
		yield put(groupCreated({ ...action.payload, id: response.result.id }));
		yield put(formSubmitted());
	} catch (e) {
		yield put(formSubmitFailed());
		//TO DO
	}
}

function* updateGroupOnServer(action) {
	try {
		const selectedAccount = store.getState().accounts.selectedAccount;
		const isAccountUpdated = selectedAccount.toString() !== action.payload.accountId.toString();
		// dispatch form submission event
		yield put(formSubmissionStarted());
		// call update API
		yield call(updateGroupApi, selectedAccount, action.payload);
		yield put(formSubmitted());
		yield put(groupUpdated({ ...action.payload, isAccountUpdated }));
	} catch (e) {
		console.log(e);
		//TO DO
		yield put(formSubmitFailed());
	}
}

export const groupSagas = [
	takeEvery(fetchGroups.type, getGroupsFromServer),
	takeEvery(fetchAllGroups.type, getAllGroupsFromServer),
	takeEvery(groupSelected.type, persistGroupSelectionInLocalStorage),
	takeLatest(createGroup.type, createGroupOnServer),
	takeLatest(updateGroup.type, updateGroupOnServer),
];
