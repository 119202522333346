import axios from "axios";
import { CANCEL } from "redux-saga";
import { config } from "src/config";

export const getHomeDataApi = (account, group, startDate, endDate, whitelistVersion) => {
    try {

        const url = `/accounts/${account}/groups/${group}/dashboards/home/${whitelistVersion}/?start_date=${startDate}&end_date=${endDate}`;

        const source = axios.CancelToken.source();

        const promise = axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
            cancelToken: source.token
        });


        promise[CANCEL] = () => source.cancel();

        return promise;

    } catch (e) {
        throw new Error(e.message);
    }
}

// TODO don;t need this function

export const getChecksDataApi = async (account, group, startDate, endDate, whitelistVersion,dashboardType) => {
    try {
        let url = `/accounts/${account}/groups/${group}/dashboards/checks_overview/${whitelistVersion}?start_date=${startDate}&end_date=${endDate}&type=${dashboardType}`;


        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}
export const getRequestsData = async (account, group, startDate, endDate,  dashboardType, whitelistVersion) => {
    try {

        let url = `/accounts/${account}/groups/${group}/dashboards/requests_overview/${whitelistVersion}?start_date=${startDate}&end_date=${endDate}&type=${dashboardType}`;

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}
export const getConsentDetailsApi = async (account, group, startDate, endDate, consentUnitId, whitelistVersion,dashboardType) => {
    try {

        let url = `/accounts/${account}/groups/${group}/dashboards/consent_unit_detail/${whitelistVersion}/${consentUnitId}?start_date=${startDate}&end_date=${endDate}&type=${dashboardType}`;

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const getConsentUnitsApi = async (account, group, startDate, endDate, consentUnitType, purposeId, whitelistVersion,dashboardType,tableVersion) => {
    try {

        let url = `/accounts/${account}/groups/${group}/dashboards/purpose_trackers/${whitelistVersion}/${consentUnitType}/${purposeId}?start_date=${startDate}&end_date=${endDate}&type=${dashboardType}&table=${tableVersion}`;

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const getDashboardDataApi = async (account, group, startDate, endDate, dataType, whitelistVersion, dashboardType) => {
    try {
        let url = `/accounts/${account}/groups/${group}/dashboards/${dataType}/${whitelistVersion}?start_date=${startDate}&end_date=${endDate}&type=${dashboardType}`;

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}
