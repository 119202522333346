import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchTrackerSuggestions, trackerSuggestionsFetched } from '../trackers';
import { fetchTrackerSuggestionsApi } from 'src/api/trackers';

function* getTrackerSuggestionsFromServer(action) {

    try {
        const { matchString, type } = action.payload;

        const response = yield call(fetchTrackerSuggestionsApi, matchString, type);
        yield put(trackerSuggestionsFetched(response.results));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}



export const trackerSagas = [
    takeLatest(fetchTrackerSuggestions, getTrackerSuggestionsFromServer),
]

