import { createAction, createSlice } from '@reduxjs/toolkit';

const privacyNoticeSlice = createSlice({
    initialState: {
        isLoading: false,
        configuration: null
    },
    name: 'privacyNotice',
    reducers: {
        privacyNoticeConfigurationUpserted: (state, { payload }) => {
            state.configuration = payload;
        },
        fetchPrivacyNoticeConfigurations: (state, action) => {
            state.isLoading = true;
        },
        PrivacyNoticeConfigurationsFetched: (state, action) => {
            state.configuration = action.payload;
            state.isLoading = false;
        }
    }
});


export const {
    privacyNoticeConfigurationUpserted,
    fetchPrivacyNoticeConfigurations,
    PrivacyNoticeConfigurationsFetched
} = privacyNoticeSlice.actions;

// Action Creators
export const upsertPrivacyNoticeConfiguration = createAction('privacyNotice/upsert');



export default privacyNoticeSlice.reducer;