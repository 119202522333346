import axios from "axios";

import { config } from '../config';

// credentials => {email, password}
export const fetchAccountsApi = async () => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: 'accounts',
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const createAccountApi = async (account) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: 'accounts',
            method: 'post',
            data: account
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const updateAccountApi = async (account) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: 'accounts',
            method: 'put',
            data: account
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}