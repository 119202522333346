import axios from "axios";
import { config } from "src/config";

// credentials => {email, password}
/**
 * 
 * @param {Object} credentials 
 * @param {string} credentials.email
 * @param {string} credentials.password
 */
export const authenticateUser = async (credentials) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: 'auth',
            method: 'post',
            data: credentials
        });
        return response.data;
    } catch (e) {
        // handle known errors
        if (e.response.status === 401) {
            throw new Error('Foutieve gebruikersnaam of wachtwoord');
        }
        throw new Error(e.message);
    }
}

/**
 * 
 * @param {string} token 
 */

export const authenticateUserByLoginToken = async (token) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: 'auth-token',
            method: 'post',
            data: token
        });
        return response.data;
    } catch (e) {
        // handle known errors
        if (e.response.status === 401) {
            throw new Error('Foutieve gebruikersnaam of wachtwoord');
        }
        throw new Error(e.message);
    }
}

/**
 * logout user
 */

export const logoutUser = async () => {
    try {
        window.location.href = config.getApiUrl() + '/logout';
    } catch (e) {
        // handle known errors
    }
}