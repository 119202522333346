import { takeLatest, call, put } from 'redux-saga/effects';
import {
    fetchCurrentVersion,
    currentVersionFetched,
    fetchWhitelist,
    whitelistFetched,
    createWhitelistEntity,
    whitelistEntityCreated,
    updateWhitelistEntity,
    whitelistEntityUpdated,
    deleteWhitelistEntity,
    whitelistEntityDeleted,
    publishWhitelist,
    whitelistPublished,
    whitelistPublishingFailed
} from '../whitelist';
import {
    createWhitelistApi,
    fetchCurrentVersionApi,
    fetchWhitelistApi,
    updateWhitelistEntityApi,
    deleteWhitelistEntityApi,
    publishWhitelistApi
} from '../../api/whitelist';
import store from '..';
import { addToast, formSubmissionStarted, formSubmitFailed, formSubmitted, toggleModal } from '../ui';
import { whitelistEntities, entityMappingSingle } from '../../utils/constants';
import { invalidateCachedData } from '../dashboard';

function* getCurrentVersionFromServer(action) {

    const groupId = store.getState().groups.selectedGroup;
    const selectedAccount = store.getState().accounts.selectedAccount;


    try {
        const currentVersionResponse = yield call(fetchCurrentVersionApi, selectedAccount, groupId);
        yield put(currentVersionFetched(currentVersionResponse.result));
    } catch (e) {
    }

}


function* getWhitelistFromServer(action) {

    const groupId = store.getState().groups.selectedGroup;
    const selectedAccount = store.getState().accounts.selectedAccount;

    try {
        const whitelistResponse = yield call(fetchWhitelistApi, selectedAccount, groupId);
        yield put(whitelistFetched(whitelistResponse.result));
    } catch (e) {
    }

}

function* createEntityOnServer(action) {
    const groupId = store.getState().groups.selectedGroup;
    const selectedAccount = store.getState().accounts.selectedAccount;

    const { entityName, entity, formId } = action.payload;

    try {
        // dispatch form submission start action
        yield put(formSubmissionStarted());
        // call the api
        const { result } = yield call(createWhitelistApi, selectedAccount, groupId, entityName, entity);
        // get the created entity
        let createdEntity = result;
        // populate fields because API just send the ids of referenced entities
        createdEntity = repopulateFields(createdEntity, entityName);
        // dispatch created entity event
        yield put(whitelistEntityCreated({ entityName, entity: createdEntity }));
        // dispatch form submit event so that form gets cleared
        yield put(formSubmitted());
        yield put(toggleModal(formId));

        const modifiedEntityName = entityMappingSingle[entityName].toLowerCase();
        yield put(addToast({
            color: 'success',
            headerMessage: 'Gelukt',
            message: `De ${modifiedEntityName} is succesvol toegevoegd.`
        }));
    } catch (e) {
        console.log(e);
        //TO DO
        yield put(formSubmitFailed())
    }


}

function* updateEntityOnServer(action) {
    const groupId = store.getState().groups.selectedGroup;
    const selectedAccount = store.getState().accounts.selectedAccount;

    let { entityName, entity } = action.payload;

    try {
        // dispatch form submission event
        yield put(formSubmissionStarted());
        // call update API
        yield call(updateWhitelistEntityApi, selectedAccount, groupId, entityName, entity);
        entity = repopulateFieldsOnUpdate(entity, entityName);
        yield put(whitelistEntityUpdated({ entityName, entity }));
        yield put(formSubmitted());
        yield put(toggleModal());
        const modifiedEntityName = entityMappingSingle[entityName].toLowerCase();

        yield put(addToast({
            color: 'success',
            headerMessage: 'Gelukt',
            message: `De ${modifiedEntityName} is succesvol gewijzigd.`
        }));
    } catch (e) {
        console.log(e);
        //TO DO
        yield put(formSubmitFailed())
    }
}

function* deleteEntityFromServer(action) {
    const groupId = store.getState().groups.selectedGroup;
    const selectedAccount = store.getState().accounts.selectedAccount;

    const { entityName, entity } = action.payload;

    try {
        yield call(deleteWhitelistEntityApi, selectedAccount, groupId, entityName, entity);
        yield put(whitelistEntityDeleted(action.payload));
        const modifiedEntityName = entityMappingSingle[entityName].toLowerCase();
        yield put(addToast({
            color: 'success',
            headerMessage: 'Gelukt',
            message: `De ${modifiedEntityName} is succesvol verwijderd.`
        }));
    } catch (e) {
        yield call(errorHandler, e);
    }
}

function* publishWhitelistOnServer(action) {
    const groupId = store.getState().groups.selectedGroup;
    const selectedAccount = store.getState().accounts.selectedAccount;
    const selectedWhitelistVersion = store.getState().whitelist.selectedVersion;


    try {
        yield call(publishWhitelistApi, selectedAccount, groupId);
        yield put(whitelistPublished(action.payload));
        yield put(fetchCurrentVersion());
        if (!selectedWhitelistVersion) {
            // a whitelist version is not selected so
            // invalidate the dashboard caching
            yield put(invalidateCachedData());
        }
        yield put(addToast({
            color: 'success',
            headerMessage: 'Gelukt',
            message: `De configuratie is succesvol gepubliceerd.`
        }));
    } catch (e) {
        yield put(whitelistPublishingFailed())
    }
}


export const whitelistSagas = [
    takeLatest(fetchCurrentVersion.type, getCurrentVersionFromServer),
    takeLatest(fetchWhitelist.type, getWhitelistFromServer),
    takeLatest(createWhitelistEntity.type, createEntityOnServer),
    takeLatest(updateWhitelistEntity.type, updateEntityOnServer),
    takeLatest(deleteWhitelistEntity.type, deleteEntityFromServer),
    takeLatest(publishWhitelist.type, publishWhitelistOnServer),


]

// util functions

//For the entity we have a list of related entities. With the populateEntities function we will retrieve these entities by id
function repopulateFields(entity, entityName) {
    switch (entityName) {
        case whitelistEntities.PERMISSIONS:
        case whitelistEntities.RULES:
            entity.purposes = populateEntities(entity.purposes || [], whitelistEntities.PURPOSES);
            break;
        case whitelistEntities.PURPOSES:
            entity.permission = populateEntities([entity.permissionId], whitelistEntities.PERMISSIONS)[0];
            entity.rules = populateEntities(entity.rules, whitelistEntities.RULES);
            break;
        default:
            break
    }

    return entity;
}

//For the entity we have a list of related entities. With the populateEntities function we will retrieve these entities by id
function repopulateFieldsOnUpdate(entity, entityName) {
    switch (entityName) {
        case whitelistEntities.PURPOSES:
            entity.permission = populateEntities([entity.permissionId], whitelistEntities.PERMISSIONS)[0];
            entity.rules = populateEntities(entity.rules, whitelistEntities.RULES);
            break;
        default:
            break
    }

    return entity;
}

function populateEntities(entityIds, entityName) {
    const allEntites = store.getState().whitelist.currentWhitelist[entityName];
    //We filter the entities from the state against the newly retrieved list
    return allEntites.filter(entity => entityIds.indexOf(entity.id) !== -1);
}

function* errorHandler(e) {

    // KNOWN ERRORS
    // 1. LINKED ERRORS
    if (e.response && e.response.data && e.response.data.linkedItems) {
        // ERROR IS NOW HANDLED FROM INTERCEPTOR
        // let message = e.response.data.message;
        // yield put(addToast({
        //     color: 'danger',
        //     headerMessage: 'Error',
        //     message
        // }));
    } else {
        yield put(formSubmitFailed());
    }

}

