import axios from "axios";
import { config } from "src/config";

export const fetchCurrentVersionApi = async (accountId, groupId) => {
    try {

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups/${groupId}/whitelists/versions`,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }


}

export const fetchWhitelistApi = async (accountId, groupId) => {
    try {

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups/${groupId}/whitelists/current`,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }

}

export const createWhitelistApi = async (accountId, groupId, entityName, entity) => {
    try {

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups/${groupId}/${entityName}`,
            method: 'post',
            data: entity
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const updateWhitelistEntityApi = async (accountId, groupId, entityName, entity) => {
    try {

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups/${groupId}/${entityName}/`,
            method: 'put',
            data: entity
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const deleteWhitelistEntityApi = async (accountId, groupId, entityName, entitiy) => {
    try {

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups/${groupId}/${entityName}/${entitiy.id}`,
            method: 'DELETE',
            data: entitiy
        });
        return response.data;
    } catch (e) {
        throw e;
    }
}

export const publishWhitelistApi = async (accountId, groupId) => {
    try {

        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups/${groupId}/whitelists/`,
            method: 'POST'
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}
