import axios from "axios";
import { config } from "src/config";
import { logLevels, logSeverity } from "./constants";

export const logOnWatchtower = async (levelname = logLevels.ERROR, message, data, severity = logSeverity.ERROR, isDevMode = false) => {

    const payload = {
        time: new Date().toISOString(),
        name: 'consent_monitor_ui',
        levelname,
        severity,
        message,
        data,
        dev: window.location.hostname === "localhost"
    }


    try {
        const response = await axios.request({
            url: config.getWatchtowerUrl(),
            method: 'post',
            data: payload,
            headers: {
                Authorization: `Token ${config.getWatchTowerToken()}`
            }
        });
        return response.data;
    } catch (e) {
        // throw new Error(e.message);
    }
}
