import axios from "axios";
import { config } from "src/config";

// credentials => {email, password}
export const fetchGroupsApi = async (accountId) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `/accounts/${accountId}/groups`,
            method: 'get',
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const createGroupApi = async (accountId, group) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups`,
            method: 'post',
            data: group
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}

export const updateGroupApi = async (accountId, group) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `accounts/${accountId}/groups`,
            method: 'put',
            data: group
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}