import { createSlice } from '@reduxjs/toolkit';

const trackerSlice = createSlice({
    initialState: {
        trackerSuggestions: null,
        loadingTrackerSugesstions: false
    },
    name: 'trackers',
    reducers: {
        fetchTrackerSuggestions: (state, action) => {
            state.loadingTrackerSugesstions = null;
            state.loadingTrackerSugesstions = true;
        },
        trackerSuggestionsFetched: (state, action) => {
            state.loadingTrackerSugesstions = false;
            state.trackerSuggestions = action.payload;
        },
        resetTrackerState: (state, action) => {
            state.loadingTrackerSugesstions = false;
            state.trackerSuggestions = null;
        }
    }
});

export const { fetchTrackerSuggestions, trackerSuggestionsFetched, resetTrackerState } = trackerSlice.actions;

export default trackerSlice.reducer;