export const formStates = {
	INITIAL: "initial",
	SUBMITTING: "submitting",
	SUBMITTED: "submitted",
	ERROR: "error",
};

export const formModes = {
	CREATE: "create",
	EDIT: "edit",
};

export const matchTypes = [
	{
		label: "Gelijk aan",
		value: "equals",
	},
	{
		label: "Bevat",
		value: "contains",
	},
	{
		label: "Reguliere expressie",
		value: "regex",
	},
	{
		label: "Start met",
		value: "startsWith",
	},
	{
		label: "Eindigt met",
		value: "endsWith",
	},
];

export const ruleTypes = [
	{
		label: "Cookie",
		value: "cookie",
	},
	{
		label: "Netwerkverzoek",
		value: "request",
	},
	{
		label: "Local storage",
		value: "localStorage",
	},
	{
		label: "Session storage",
		value: "sessionStorage",
	},
];

export const userRoles = [
	{
		label: "Admin",
		value: "1",
	},
	{
		label: "User",
		value: "2",
	},
];

export const entityMappingPlural = {
	rules: "Detectieregels",
	purposes: "Verwerkingen",
	permissions: "Toestemmingen",
};
export const entityMappingSingle = {
	rules: "Detectieregel",
	purposes: "Verwerking",
	permissions: "Toestemming",
};

export const whitelistEntities = {
	PERMISSIONS: "permissions",
	RULES: "rules",
	PURPOSES: "purposes",
};

export const appEntities = {
	...whitelistEntities,
	PRIVACY_NOTICE: 'privacy-notice',
	HOSTS: "hosts",
	GROUPS: "groups",
	WHITELIST: "whitelist",
	ROLES: "roles",
	USERS: "users",
	INSTALL: "install",
};

export const dashboardTypes = {
	CHECKS: "checks_overview",
	REQUESTS: "requests_overview",
	HOME: "home",
	CONSENT_UNIT_DETAILS: "consentUnitDetails",
	UNKNOWN_TRACKERS: "unknown_trackers",
	CHECKS_PER_RULE: "rule_trackers",
	INSTALL_TRACKERS: "install",
};

export const dashboardSubtypes = {
	OUTLIERS: "outliers",
	REALTIME: "realTime",
	HISTORIC: "historic",
};

export const consentUnitTypes = {
	REQUEST: "request",
	COOKIE: "cookie",
	SESSION_STORAGE: "sessionstorage",
	LOCAL_STORAGE: "localstorage",
};

export const logLevels = {
	DEBUG: "DEBUG",
	INFO: "INFO",
	WARNING: "WARNING",
	ERROR: "ERROR",
	CRITICAL: "CRITICAL",
};

export const logSeverity = {
	DEBUG: 10,
	INFO: 20,
	WARNING: 30,
	ERROR: 40,
	CRITICAL: 50,
};

export const loggedInUserRoles = {
	USER: "user",
	ADMIN: "admin",
};

export const groupRoles = {
	ADMIN: "1",
	USER: "2",
};
