import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store/'
import { logOnWatchtower } from './utils/watchtower';
import { logLevels, logSeverity } from './utils/constants';
import { initLanguageService } from './i18n';

React.icons = icons

initLanguageService();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

window.addEventListener('error', function (event) {
    if (typeof event.error === "object" && event.error) {
        if (event.error.hasBeenCaught !== undefined) {
            return false
        }
        event.error.hasBeenCaught = true;
    }
    logOnWatchtower(logLevels.ERROR, event.message, {
        type: 'Javascript', accountId: store.getState().accounts.selectedAccount,
        groupId: store.getState().groups.selectedGroup,
        userId: store.getState().auth.userId
    }, logSeverity.ERROR);
})
