import React from 'react';

import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import store from './store';
import { configureNetworkSettingsForAuthenticatedUser } from './utils/axiosConfig';

const loading = (
   <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
   </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const AdminLogin = React.lazy(() => import('./views/pages/login/AdminLogin'));
const AccountsList = React.lazy(() => import('./views/accounts/AccountsList'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
   const authState = store.getState().auth;

   if (authState.isUserLoggedIn) {
      configureNetworkSettingsForAuthenticatedUser();
   }

   return (
      <HashRouter>
         <React.Suspense fallback={loading}>
            <Switch>
               <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
               <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
               <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
               <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
               <Route exact path="/accounts" name="Accounts page" render={props => <AccountsList {...props} />} />
               <Route exact path="/poweruser" name="Admin Panel" render={props => {
                  return <AdminLogin {...props} />
               }} />
               <Route path="/poweruser/app" name="Admin Panel" render={props => {
                  return <TheLayout {...props} IsPowseruser={true} />
               }} />
               <Route path="/" name="Home" render={props => {
                  return <TheLayout {...props} />
               }} />
            </Switch>
         </React.Suspense>
      </HashRouter>
   );
}

export default App;
