import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchEntitiesApi, createEntityApi, updateEntityApi, deleteEntityApi } from '../../api/entities';
import { publishHostsApi } from '../../api/hosts';


import { createHost, fetchHosts, hostsFetched, updateHost, hostUpdated, deleteHost, hostDeleted, publishHosts, hostsPublished } from '../hosts';
import { appEntities } from 'src/utils/constants';
import { addToast, formSubmissionStarted, formSubmitFailed, formSubmitted } from '../ui';
import store from '..';

function* getHostsFromServer(action) {

    try {
        // const groupId = store.getState().groups.selectedGroup;

        const response = yield call(fetchEntitiesApi, null, null , appEntities.HOSTS);
        yield put(hostsFetched(response.hosts));
    } catch (e) {
        //TO DO
        console.log(e);
    }

}

function* createHostOnServer(action) {

    try {

        yield put(formSubmissionStarted());
        const response = yield call(createEntityApi, null , null, appEntities.HOSTS, action.payload);
        yield put(hostsFetched(response.results));
        yield put(formSubmitted());
        yield put(fetchHosts());
    } catch (e) {
        yield put(formSubmitFailed());
        //TO DO
        console.log(e);
    }

}

function* updateHostOnServer(action) {

    try {
        const groupId = store.getState().groups.selectedGroup;

        // dispatch form submission event
        yield put(formSubmissionStarted());
        // call update API
        yield call(updateEntityApi, null , groupId, appEntities.HOSTS, action.payload);
        yield put(hostUpdated(action.payload));
        yield put(formSubmitted());
    } catch (e) {
        console.log(e);
        //TO DO
        yield put(formSubmitFailed())
    }
}

function* deleteHostFromServer(action) {

    try {
        // const groupId = store.getState().groups.selectedGroup;

        yield call(deleteEntityApi, null, null, `${appEntities.HOSTS}/${action.payload.id}`);
        yield put(hostDeleted(action.payload));
    } catch (e) {
        yield put(formSubmitFailed())
    }
}

function* publishHostsOnServer(action) {

    const groupId = store.getState().groups.selectedGroup;


    try {
        yield call(publishHostsApi, groupId, action.payload);
        yield put(hostsPublished());
        yield put(addToast({
            color: 'success',
            headerMessage: 'Success',
            message: 'Hosts publised!'
        }))
    } catch (e) {
        yield put(formSubmitFailed())
    }
}

export const hostsSagas = [
    takeLatest(fetchHosts.type, getHostsFromServer),
    takeLatest(createHost.type, createHostOnServer),
    takeLatest(updateHost.type, updateHostOnServer),
    takeLatest(deleteHost.type, deleteHostFromServer),
    takeLatest(publishHosts.type, publishHostsOnServer),




]

