
import { createAction, createSlice } from '@reduxjs/toolkit';



const rolesSlice = createSlice({
    initialState: { allRoles: [], isLoaidng: false },
    name: 'roles',
    reducers: {
        rolesFetched: (state, action) => {
            state.allRoles = action.payload;
            state.allRoles = state.allRoles.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            state.isLoading = false;
        },
        fetchRoles: (state, action) => {
            state.isLoading = true;
        },
        roleCreated: (state, action) => {
            state.allRoles.push(action.payload);
        },
        roleDeleted: (state, action) => {
            const index = state.allRoles.findIndex(role => role.id === action.payload.id);
            state.allRoles.splice(index, 1);
        }
    }
});

// Action Creators
export const createRole = createAction('groups/createRole');
export const deleteRole = createAction('groups/deleteRole');

export const { rolesFetched, fetchRoles, roleCreated, roleDeleted } = rolesSlice.actions;

export default rolesSlice.reducer;


